import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
export default function SearchNotFound({ searchQuery = "" }) {
  const { t } = useTranslation();
  return (
    <>
      <Typography gutterBottom align="center" variant="subtitle1">
        {t("Not found")}
      </Typography>
      <Typography variant="body2" align="center">
        {t("No results found for")} &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>.
        {t("Try checking for typos or using complete words.")}
      </Typography>
    </>
  );
}
